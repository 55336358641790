import React, { PropsWithChildren, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"

type Props = {
  onOpen: () => void
  onClose: () => void
}
export const DocumentPictureInPicture: React.FC<PropsWithChildren<Props>> = ({
  onOpen,
  onClose,
  children,
}) => {
  const [pip, setPip] = useState<Window | null>(null)
  const observerRef = useRef<MutationObserver | null>(null)

  const hidePip = () => {
    onClose()
    setPip(null)
  }

  const showPip = async () => {
    // Don't allow multiple windows
    if (pip) return

    if (!("documentPictureInPicture" in window)) {
      throw new Error("Document picture in picture not supported")
    }

    const pipWindow = await window.documentPictureInPicture.requestWindow({
      width: 400,
      height: 400,
      disallowReturnToOpener: true,
    })

    pipWindow.addEventListener("pagehide", hidePip)

    // Immediately copy styles after creating the PiP window
    copyStyles(pipWindow)

    setPip(pipWindow)
  }

  const copyStyles = (pipWindow: Window) => {
    if (!pipWindow) return

    // Clear existing styles to avoid duplication
    pipWindow.document.head.innerHTML = ""

    // Copy styles from the current page into the PIP window
    ;[...document.styleSheets].forEach((stylesheet) => {
      try {
        const styleText = [...stylesheet.cssRules]
          .map((rule) => rule.cssText)
          .join("")
        const styleElement = document.createElement("style")
        styleElement.textContent = styleText
        pipWindow.document.head.appendChild(styleElement)
      } catch (e) {
        // Can only copy same-origin stylesheets so this breaks on google fonts etc
        if (e.message.match(/Cannot access rules/)) {
          return
        }
      }
    })
  }

  useEffect(() => {
    showPip()
      .then(onOpen)
      .catch((e) => {
        // Browser might not support PIP, silently ignore in that case
        console.error(e)
      })

    return () => {
      window.documentPictureInPicture?.window?.close()
      observerRef.current?.disconnect()
    }
  }, [])

  useEffect(() => {
    if (!pip) return

    const observer = new MutationObserver(() => copyStyles(pip))
    observerRef.current = observer

    observer.observe(document.head, {
      childList: true,
      subtree: true,
    })

    // Clean up observer on component unmount or when pip changes
    return () => {
      observer.disconnect()
    }
  }, [pip])

  if (!pip) return null

  return createPortal(children, pip.document.body)
}

export const isDocumentPictureInPictureSupported = () => {
  return "documentPictureInPicture" in window
}
