import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Link,
  Text,
} from "@chakra-ui/react"
import { getCurrentResponse } from "Redux/reducers/current-response/selectors"
import { Copy06OutlineIcon } from "Shared/icons/untitled-ui/Copy06OutlineIcon"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { OuterProps } from "../../props"

type Props = {
  onClose: () => void
  validCode: boolean
  onCheckCode: (code: string) => void
  onReport: () => void
  externalStudy: NonNullable<
    OuterProps["usabilityTestSection"]["external_study"]
  >
  allowCopy?: boolean
}

export const ExternalStudyControlPanel: React.FC<Props> = ({
  onClose,
  validCode,
  onCheckCode,
  onReport,
  externalStudy,
  allowCopy = false,
}) => {
  const response = useSelector(getCurrentResponse)
  const [completionCode, setCompletionCode] = useState("")
  const participantId = response?.user_id ?? "preview"

  return (
    <Flex direction="column" p={4} gap={4} h="full">
      {externalStudy.participation_strategy === "copy_paste" && (
        <>
          <Text>
            At the start of the test, you will be asked to input your UserCrowd
            ID.
          </Text>

          <InputGroup rounded="md">
            {/* The borderRadius normally inherits but for some reason this fails inside the PIP window */}
            <InputLeftAddon roundedLeft="var(--input-border-radius)">
              UserCrowd ID
            </InputLeftAddon>

            <Flex
              flexGrow={1}
              align="center"
              borderTopWidth={1}
              borderBottomWidth={1}
              px={3}
              bg="white"
              borderRightWidth={allowCopy ? 0 : 1}
              roundedRight="var(--input-border-radius)"
            >
              <Input
                variant="unstyled"
                rounded="none"
                readOnly
                onClick={(e) => (e.target as HTMLInputElement).select()}
                value={participantId}
              />
            </Flex>
            {allowCopy && (
              <InputRightAddon px={0}>
                <IconButton
                  variant=""
                  icon={<Copy06OutlineIcon />}
                  aria-label="Copy participantId to clipboard"
                  onClick={() => {
                    navigator.clipboard.writeText(participantId.toString())
                  }}
                />
              </InputRightAddon>
            )}
          </InputGroup>
        </>
      )}
      {externalStudy.completion_strategy === "prompt" &&
        (validCode ? (
          <>
            <Text>
              When you're ready, click here to close the tab and finish the
              task:
            </Text>

            <Button colorScheme="brand.primary" onClick={onClose}>
              End task
            </Button>
          </>
        ) : (
          <>
            <Text>
              At the end of the test, you will be given a completion code to
              submit below.
            </Text>

            <Flex gap={2}>
              <Input
                flexGrow={1}
                placeholder="Completion code"
                value={completionCode}
                onChange={(e) => setCompletionCode(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onCheckCode(completionCode)
                  }
                }}
              />
              <Button
                flexShrink={0}
                colorScheme="brand.primary"
                onClick={() => onCheckCode(completionCode)}
                isDisabled={completionCode.length === 0}
              >
                Check code
              </Button>
            </Flex>
          </>
        ))}

      {externalStudy.completion_strategy === "redirect_url" && (
        <Text>
          You will automatically complete the task when it redirects you at the
          end.
        </Text>
      )}

      <Text>
        If you are unable to complete the task due to an error, please{" "}
        <Link colorScheme="red" onClick={onReport}>
          report the task
        </Link>
        .
      </Text>
    </Flex>
  )
}
