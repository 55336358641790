import { Center, Spinner } from "@chakra-ui/react"
import { Dispatch } from "Redux/app-store"
import { InitResponseData } from "Redux/reducers/current-response/action-creators"
import { ActionType as ResponseActionType } from "Redux/reducers/current-response/action-type"
import { initializeFigmaFileVersions } from "Redux/reducers/figma-file-versions/actions"
import {
  Type as ActionType,
  InitUsabilityTestData,
} from "Redux/reducers/participant-usability-test/action-types"
import { setScreenshots } from "Redux/reducers/screenshots/action-creators"
import { getInitializeScreenshots } from "Redux/reducers/screenshots/helper"
import { LocaleProvider } from "Shared/contexts/LocaleContext"
import { useTranslate } from "Shared/hooks/useTranslate"
import {
  AnswerTag,
  Language,
  RawParticipantResponse,
  RawScreenshot,
  ResponseDeletionReason,
  TestBranding,
  ThankYouMessageCopy,
  WelcomeMessageCopy,
} from "Types"
import { DeviceFramesProvider } from "UsabilityHub/contexts"
import { UsabilityTestScreener } from "UserCrowd/components/UsabilityTestScreener/UsabilityTestScreener"
import { deviceFramePath } from "Utilities/device-frame"
import { LoadImageError, loadImage } from "Utilities/image"
import { getDeviceFrameIds } from "Utilities/usability-test"
import React, { ComponentProps, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  useGetUsabilityTestResponseFlow,
  usePreviewTestSet,
  usePreviewUsabilityTest,
} from "~/api/generated/usabilityhub-components"
import { RecruitmentLink } from "~/api/generated/usabilityhubSchemas"
import { TimeoutGracePeriodModal } from "./TimeoutGracePeriodModal"
import { UsabilityTest } from "./UsabilityTest"
import {
  TestRecordingContextProvider,
  useTestRecordingContext,
} from "./context/TestRecordingContext"
import "Stylesheets/fonts/mint-grotesk.css"
import { isExternalStudy } from "UsabilityHub/utils/isExternalStudy"

type Props = {
  responseId: number
  language: Readonly<Language>
  recruitmentLink: RecruitmentLink
  testBranding: Readonly<TestBranding>
  thankYouCopy: Readonly<ThankYouMessageCopy>
  redirectLink: string
}

export const TestTakingInterface: React.FC<Props> = ({
  responseId,
  language,
  recruitmentLink,
  testBranding,
  thankYouCopy,
  redirectLink,
}) => {
  const dispatch = useDispatch<Dispatch>()
  const [syncedToRedux, setSyncedToRedux] = useState(false)

  // If we don't have a response, use the preview endpoint to get data instead
  const isPreview = !responseId
  const isTestSet = !!window.location.pathname.match(/\/s\//)

  const uniqueId = window.location.pathname
    .replace(/\/(do|preview)(\/s)?\//, "")
    .replace(/\?.*/, "")
    .split("/")[0]

  const { data: responseData } = useGetUsabilityTestResponseFlow(
    {
      pathParams: { responseId },
    },
    {
      enabled: !isPreview,
    }
  )

  const { data: previewData } = usePreviewUsabilityTest(
    {
      pathParams: { usabilityTestUniqueId: uniqueId },
    },
    {
      enabled: isPreview && !isTestSet,
    }
  )

  const { data: previewSetData } = usePreviewTestSet(
    {
      pathParams: { uniqueId },
    },
    {
      enabled: isPreview && isTestSet,
    }
  )

  const flowData = isPreview
    ? isTestSet
      ? previewSetData
      : previewData
    : responseData

  // Sync usability test state to redux
  // In MOT-160 we will remove this syncing code and install a context provider here to serve
  // this data to the component tree below.
  useEffect(() => {
    if (!flowData) return
    if (syncedToRedux) return

    if (flowData.state === "usability_test") {
      const responsePayload: Omit<RawParticipantResponse, "id"> = {
        ...flowData.current_response,
        // The existing RawResponse type has these fields which are not used in the test-taking interface
        // and the new API does not provide them. Once we remove Redux we won't use this type any more and these
        // can be deleted.
        sections: flowData.current_response.sections.map((s) => ({
          ...s,
          figma_file_version_answer: null,
          cards_sort_time: null,
          _taskFlowSuccessAcknowledged: null,
          _startTime: 0,
        })),
        answers: flowData.current_response.answers.map((a) => ({
          ...a,
          id: null,
          answer_tags: [] as ReadonlyArray<AnswerTag>,
        })),
        review_status: null,
        automated_review_status: null,
        device_type: null,
        platform: null,
        query_parameters: null,
        cint_respondent_id: null,
        deletion_reason: flowData.current_response
          .deletion_reason as ResponseDeletionReason,
      }

      dispatch<InitResponseData>({
        type: ResponseActionType.INIT_RESPONSE_DATA,
        payload: responsePayload as RawParticipantResponse, // Only necessary because of the null ID when previewing
      })

      dispatch<InitUsabilityTestData>({
        type: ActionType.INIT_USABILITY_TEST_DATA,
        payload: flowData.participant_usability_test,
      })

      dispatch(
        initializeFigmaFileVersions(
          flowData.figma_file_versions.map((ffv) => ({
            ...ffv,
            last_synced_at: new Date(ffv.last_synced_at),
            last_modified_at: new Date(ffv.last_modified_at),
            frame_titles: ffv.frame_titles as Record<string, string>,
          }))
        )
      )

      const initializedScreenshots = getInitializeScreenshots(
        // The old RawScreenshot type has a more complicated discriminated union that the OpenAPI schema
        // does not currently include. When we remove this from Redux we can decide to add it (or not).
        flowData.screenshots as unknown as ReadonlyArray<RawScreenshot>
      )
      dispatch(setScreenshots(initializedScreenshots))

      setSyncedToRedux(true)
    }
  }, [flowData])

  // Preload device frame images
  const deviceFrames =
    flowData?.state === "usability_test" ? flowData.device_frames : null
  const usabilityTestSections =
    flowData?.state === "usability_test"
      ? flowData.participant_usability_test.sections
      : null
  useEffect(() => {
    if (deviceFrames && usabilityTestSections) {
      try {
        getDeviceFrameIds(usabilityTestSections).map((id) => {
          const deviceFrame = deviceFrames.find(
            (deviceFrame) => deviceFrame.id === id
          )!
          loadImage(deviceFramePath(deviceFrame.device_frame_type))
        })
      } catch (error) {
        // Suppress image load errors in production, there's nothing we can do
        // about them.
        if (!process.env.DEBUG && !(error instanceof LoadImageError)) {
          throw error
        }
      }
    }
  }, [deviceFrames, usabilityTestSections])

  if (!flowData) {
    return (
      <Center height="full">
        <Spinner size="xl" />
      </Center>
    )
  }

  const isExternal =
    flowData.state === "usability_test" &&
    isExternalStudy(flowData.participant_usability_test)

  return (
    <LocaleProvider
      showLoader
      locale={language.code}
      keys={["test", "demographics"]}
    >
      <TestRecordingContextProvider
        isPreview={isPreview}
        recordingTypes={
          flowData.state !== "screened_out" ? flowData.test_recording_types : []
        }
      >
        {flowData.state === "screener" && (
          <UsabilityTestScreener
            usabilityTestUniqueId={flowData.test_info.usability_test_unique_id}
            language={language}
            isPanelist={flowData.is_panelist}
            responseId={responseId}
            testInfo={flowData.test_info}
            questions={flowData.screener_questions}
          />
        )}

        {flowData.state !== "screened_out" && flowData.is_panelist && (
          <TimeoutGracePeriodModal idleTimeoutMs={flowData.idle_timeout_ms} />
        )}

        {flowData.state === "screened_out" && (
          <Center h="full">Screened out</Center>
        )}

        {flowData.state === "usability_test" && syncedToRedux && (
          <DeviceFramesProvider deviceFrames={deviceFrames!}>
            <UsabilityTestWithTranslations
              language={language}
              recruitmentLink={recruitmentLink}
              testBranding={testBranding}
              redirectLink={redirectLink}
              usabilityTest={flowData.participant_usability_test}
              providedThankYouCopy={thankYouCopy}
              isExternalStudy={isExternal}
            />
          </DeviceFramesProvider>
        )}
      </TestRecordingContextProvider>
    </LocaleProvider>
  )
}

type UsabilityTestWithTranslationsProps = Omit<
  ComponentProps<typeof UsabilityTest>,
  | "welcomeCopy"
  | "thankYouCopy"
  | "areAllRecordingsUploaded"
  | "cleanupScreenStream"
  | "recordingSetupGuideFinished"
  | "allowedRecordingTypes"
> & {
  providedThankYouCopy: ThankYouMessageCopy | null
  usabilityTest: WelcomeMessageCopy & { customize_welcome: boolean }
}

const UsabilityTestWithTranslations: React.FC<
  UsabilityTestWithTranslationsProps
> = ({ providedThankYouCopy, usabilityTest, ...props }) => {
  const t = useTranslate()

  const defaultWelcomeCopy = constructLocaleSlice(t, "test.welcome", [
    "welcome_heading",
    "welcome_message",
    "welcome_button_text",
    "audio_video_heading",
    "audio_video_message",
  ])

  const defaultThankYouCopy = constructLocaleSlice(t, "test.thankyou", [
    "thankyou_heading",
    "thankyou_message",
    "about_to_be_redirected",
  ])

  const {
    allowedRecordingTypes,
    areAllRecordingsUploaded,
    cleanupScreenStream,
    recordingSetupGuideFinished,
  } = useTestRecordingContext()

  if (allowedRecordingTypes?.length > 0) {
    defaultWelcomeCopy.welcome_button_text = t("test.buttons.get_started")
  }

  const welcomeCopy = usabilityTest.customize_welcome
    ? usabilityTest
    : defaultWelcomeCopy

  const thankYouCopy = providedThankYouCopy ?? defaultThankYouCopy

  return (
    <UsabilityTest
      {...props}
      welcomeCopy={welcomeCopy}
      thankYouCopy={thankYouCopy}
      areAllRecordingsUploaded={areAllRecordingsUploaded}
      allowedRecordingTypes={allowedRecordingTypes}
      cleanupScreenStream={cleanupScreenStream}
      recordingSetupGuideFinished={recordingSetupGuideFinished}
    />
  )
}

const constructLocaleSlice = <T extends string>(
  t: ReturnType<typeof useTranslate>,
  prefix: string,
  keys: T[]
) => {
  return keys.reduce(
    (acc, key) => {
      return {
        ...acc,
        [key]: t(`${prefix}.${key}`) ?? "",
      }
    },
    {} as { [K in (typeof keys)[number]]: string }
  )
}
